import React, {
	FC,
	LegacyRef,
	ReactNode,
	useEffect,
	useMemo,
	useRef,
} from "react";
import {
	Box,
	Flex,
	HStack,
	Stack,
	useColorModeValue,
	VStack,
} from "@chakra-ui/react";
import Sidebar from "@components/navigation/sidebar/sidebar";
import { Footer } from "@components/footer/footer";
import Navbar, { NAVBAR_HEIGHT } from "@components/layout/navbar/navbar";
import { mainBgColor } from "@definitions/chakra/theme/foundations/colors";
import { UserTypeEnum } from "@interfaces/api/auth.types";
import useAuthContext from "@hooks/useAuthContext";
import { useRouter } from "next/router";
import useGDPRQuery from "@hooks/useGDPRQuery";

interface SharedLayoutProps {
	children?: ReactNode;
}

const SharedLayout: FC<SharedLayoutProps> = ({ children }) => {
	const childrenContainerRef = useRef(null);
	const router = useRouter();

	useGDPRQuery();

	const { authData } = useAuthContext();

	const bgColor = useColorModeValue(mainBgColor[0], mainBgColor[1]);
	const homepageBgColor = useColorModeValue("white", mainBgColor[1]);

	useEffect(() => {
		if (typeof window !== "undefined") {
			//@ts-ignore
			window.childrenContainerRef = childrenContainerRef;
		}
	}, [childrenContainerRef.current]);

	const sidebarShown = useMemo(
		() =>
			authData?.role.value === UserTypeEnum.Admin ||
			authData?.role.value === UserTypeEnum.Consultant,
		[authData],
	);

	return (
		<VStack
			bg={router.pathname === "/" ? homepageBgColor : bgColor}
			top={0}
			left={0}
			right={0}
			bottom={0}
			overflow={"hidden"}
			position={"relative"}
		>
			<Navbar />
			<Stack
				direction={{ base: "column", lg: "row" }}
				spacing={0}
				m={0}
				align={"start"}
				w={"100%"}
				h={`calc(100vh - ${NAVBAR_HEIGHT}px)`}
				overflow={"auto"}
				ref={childrenContainerRef}
			>
				{sidebarShown && (
					<Box flex={1} zIndex={999}>
						<Sidebar />
					</Box>
				)}
				<Flex direction="column" flex={18} zIndex={{ base: 1, lg: 0 }}>
					<Box px={{ lg: 3, sm: 0 }}>{children}</Box>
				</Flex>
			</Stack>
		</VStack>
	);
};

export default SharedLayout;
