import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import FormInput from "@components/form/form-input/form-input";
import { Button } from "@components";
import { ILoginReq } from "@interfaces/api/auth.types";
import {
	Box,
	Stack,
	StackDirection,
	useColorModeValue,
} from "@chakra-ui/react";
import { brandColor } from "@definitions/chakra/theme/foundations/colors";
import { useRouter } from "next/router";


interface IFormData {
	login: string;
	password?: string;
}

export interface LogInFormProps {
	onSubmit: (values: ILoginReq) => void;
	onResetPassword?: (values: Omit<ILoginReq, "password">) => void;
	direction: StackDirection;
	initValues?: IFormData
}



const LogInForm: React.FC<LogInFormProps> = ({
	onSubmit: onFormSubmit,
	onResetPassword,
	direction,
	initValues,
}) => {
	const {
		register,
		handleSubmit,
		formState: { errors, isSubmitting },
		formState,
	} = useForm<IFormData>({defaultValues: initValues});

	const router = useRouter();
	const { passwordReset, email } = router.query;

	const [resetPassword, setResetPassword] = useState(false);

	const activeColor = useColorModeValue("white", "white");
	const buttonBg = useColorModeValue(brandColor[0], brandColor[1]);

	useEffect(() => {
		if (router.query.passwordReset) {
			setResetPassword(true);
		}
	}, [passwordReset]);

	const onSubmit = (values: IFormData) =>
		resetPassword
			? onResetPassword && onResetPassword(values)
			: onFormSubmit(values);

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Stack direction={direction} spacing={4} align={"center"} maxW={300}>
				<FormInput
					name={"login"}
					label={"Email"}
					formState={formState}
					formRegister={register}
				/>
				<Box>
					{!resetPassword && (
						<FormInput
							name={"password"}
							label={"Password"}
							type={"password"}
							formState={formState}
							formRegister={register}
						/>
					)}
					{!!onResetPassword && (
						<Button
							variant={"link"}
							fontSize={12}
							display={"block"}
							onClick={() => setResetPassword(!resetPassword)}
						>
							{resetPassword ? "Log in" : "Forgot password?"}
						</Button>
					)}
				</Box>
				<Button
					type="submit"
					bg={buttonBg}
					color={activeColor}
					isLoading={isSubmitting}
				>
					{resetPassword ? "Reset password" : "Log in"}
				</Button>
			</Stack>
		</form>
	);
};

export default LogInForm;
