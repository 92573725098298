import React, { FC, ReactNode, useEffect, useRef } from "react";
import { Stack, StackProps } from "@chakra-ui/react";

interface PageContainerProps extends StackProps {
	children?: ReactNode;
	initScroll?: { x: number; y: number } | null;
}

export const PageContainer: FC<PageContainerProps> = ({
	children,
	initScroll = { x: 0, y: 0 },
	...rest
}) => {
	const containerRef = useRef(null);

	useEffect(() => {
		if (typeof window !== "undefined") {
			initScroll &&
				//@ts-ignore
				// push
				window?.childrenContainerRef?.current?.scrollTo({
					top: initScroll?.y,
					left: initScroll?.x,
					behavior: "smooth",
				});
		}
	}, []);

	return (
		<Stack
			ref={containerRef}
			direction={{ sm: "column", lg: "row" }}
			align={"start"}
			justify={"start"}
			w={{ base: "100vw", lg: "unset" }}
			spacing={2}
			{...rest}
		>
			{children}
		</Stack>
	);
};
