import { createContext, FC, useEffect, useState } from "react";
import { IUser } from "@interfaces/api/auth.types";
import { readFromStorage, writeToStorage } from "@utils/local-storage";
import {
	LSAuthData,
	LSAuthToken,
	LSTemporaryToken,
} from "@definitions/local-storage.keys";
import { refreshAuthToken as refreshToken } from "@api/auth.api";

export type ChatContextType = {
	authToken: string | null;
	authData: IUser | null;
	updateAuthToken: (token: string | null) => void;
	updateAuthData: (data: IUser | null) => void;
	refreshAuthToken: () => Promise<void>;
	timestamp: number;
};

export const AuthContext = createContext<ChatContextType | null>(null);

const AuthContextProvider: FC = ({ children }) => {
	const [authToken, setAuthToken] = useState<string | null>("");
	const [authData, setAuthData] = useState<IUser | null>(null);
	const [timestamp, setTimestamp] = useState(Date.now());

	useEffect(() => {
		setAuthToken(
			readFromStorage(LSTemporaryToken) || readFromStorage(LSAuthToken),
		);
		setAuthData(readFromStorage(LSAuthData));
		setTimestamp(Date.now());
	}, []);

	useEffect(() => {
		checkAuthTokenValidity();
	}, [authData]);

	const checkAuthTokenValidity = () => {
		if (authData?.validTo && !!authToken) {
			const validTillDateInMilliseconds = new Date(authData.validTo).getTime();
			const dateNowInMilliseconds = Date.now();
			const millisecondsInHour = 60 * 60 * 1000;
			const shouldRefresh =
				validTillDateInMilliseconds - dateNowInMilliseconds <
				millisecondsInHour * 8;

			shouldRefresh && refreshToken();
		}
	};

	const refreshAuthToken = async () => {
		try {
			const { authToken, ...authData } = await refreshToken();
			updateAuthToken(authToken);
			updateAuthData(authData);
		} catch (e) {
			console.log(e);
		}
	};

	const updateAuthToken = (token: string | null) => {
		writeToStorage(LSAuthToken, token);
		setAuthToken(token);
		setTimestamp(Date.now());
	};

	const updateAuthData = (data: IUser | null) => {
		writeToStorage(LSAuthData, data);
		setAuthData(data);
		setTimestamp(Date.now());
	};

	return (
		<AuthContext.Provider
			value={{
				authToken,
				authData,
				updateAuthToken,
				updateAuthData,
				refreshAuthToken,
				timestamp,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

export default AuthContextProvider;
