import { useRouter } from "next/router";
import { Obj } from "@interfaces/types";

const useShallowRoute = () => {
	const router = useRouter();

	return (pathname: string, query: Obj) =>
		router.push(
			{
				pathname,
				query,
			},
			undefined,
			{ shallow: true },
		);
};

export default useShallowRoute;
