import { ThemeOverride } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

type GlobalStyles = Pick<ThemeOverride, "styles">;

export default {
	styles: {
		global: (props) => ({
			html: {
				fontFamily: "Helvetica, sans-serif",
			},
			body: {
				bg: mode("gray.100", "gray.800")(props),
				fontFamily: "Helvetica, sans-serif",
				overflow: "hidden",
			},
			h1: {
				fontWeight: 500,
				marginBottom: "0.5em",
			},
			p: {
				marginBottom: "1em",
			},
			ul: {
				pl: "20px",
			},
			ol: {
				pl: "20px",
			},
			/* width */
			"::-webkit-scrollbar": {
				width: "6px",
				height: "6px",
			},

			/* Track */
			"::-webkit-scrollbar-track": {
				background: "transparent",
				opacity: 0.4,
			},

			/* Handle */
			"::-webkit-scrollbar-thumb": {
				background: "#2a69ac",
				opacity: 0.4,
				borderRadius: 5,
			},

			/* Handle on hover */
			"::-webkit-scrollbar-thumb:hover": {
				background: "#4a89c9",
				opacity: 0.4,
				cursor: "pointer",
			},
			".ProseMirror": {
				mt: 0,
				minH: "62px",
				"> * + *": {
					marginTop: "0.75em",
				},
				// @see https://github.com/jesster2k10/guava-cards/blob/5d5c283eb720bf503258f4e17bce3865d35fd8d3/packages/website/src/bundles/editor/ContentEditor.tsx#L86
				"p.is-editor-empty:first-child::before": {
					content: "attr(data-placeholder)",
					color: "gray.500",
					float: "left",
					pointerEvents: "none",
					height: 0,
				},
				"&:focus": {
					outline: "none",
				},
				p: {
					m: 0,
				},
				h1: {
					fontSize: "1.25rem",
				},
				h2: {
					fontSize: "1.15rem",
				},
				h3: {
					fontSize: "1rem",
				},
				"h1, h2, h3, h4,  h5, h6 ": {
					lineHeight: "1.1",
					fontWeight: "700",
				},
				"ul, ol": {
					padding: "0 1.2rem",
				},
				code: {
					bg: "rgba(#616161, 0.1)",
					color: "#616161",
				},
				pre: {
					fontFamily: "JetBrainsMono, 'Courier New', Courier, monospace",
					background: mode("gray.900", "blue.200")(props),
					color: mode("white", "gray.900")(props),
					padding: "0.75rem 1rem",
					rounded: "lg",
					whiteSpace: "pre-wrap",
					code: {
						color: "inherit",
						p: 0,
						background: "none",
						fontSize: "0.8rem",
					},

					".hljs-comment, .hljs-quote": {
						color: "#616161",
					},

					".hljs-variable, .hljs-template-variable,  .hljs-attribute, .hljs-tag, .hljs-name, .hljs-regexp, .hljs-link, .hljs-name, .hljs-selector-id, .hljs-selector-class":
						{
							color: "#F98181",
						},

					".hljs-number,  .hljs-meta, .hljs-built_in, .hljs-builtin-name, .hljs-literal,  .hljs-type, .hljs-params":
						{
							color: "#FBBC88",
						},

					".hljs-string, .hljs-symbol, .hljs-bullet": {
						color: "#B9F18D",
					},

					".hljs-title, .hljs-section": {
						color: "#FAF594",
					},

					".hljs-keyword, .hljs-selector-tag": {
						color: "#70CFF8",
					},

					".hljs-emphasis": {
						fontStyle: "italic",
					},

					".hljs-strong": {
						fontWeight: 700,
					},
				},
				blockquote: {
					pl: 4,
					borderLeft: "2px solid rgba(13, 13, 13, 0.1)",
					borderLeftColor: mode(undefined, "whiteAlpha.500")(props),
				},
				"span[data-spoiler]": {
					bg: mode("gray.900", "gray.100")(props),
					_hover: {
						bg: "transparent",
					},
					// @apply dark:bg-gray-100 bg-gray-900 dark:hover:bg-transparent hover:bg-transparent;
				},
				img: {
					maxW: "full",
					h: "auto",
				},
				mark: {
					bg: "#FAF594",
				},
				hr: {
					border: "none",
					borderTop: "2px solid rgba(13,13,13,.1)",
					margin: "2rem 0",
				},
			}, // .ProseMirror
		}),
	},
} as GlobalStyles;
