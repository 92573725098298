import { useState, FC, useCallback } from "react";
import { AsyncCreatableSelect } from "chakra-react-select";
import { getTags } from "@api/tags.api";
import { ITag } from "@interfaces/api/tags.types";
import debounce from "@utils/debounce";
import { Box, FormLabel, Heading } from "@chakra-ui/react";

export interface ITagOption {
	label: string;
	value: string | number;
	data?: any;
}

interface FormAsyncSelectProps {
	onSelect: (tag: ITagOption) => void;
	placeholder?: string;
	disabled?: boolean;
	isMutli?: boolean;
	label?: string;
	value?: any;
}

const TagAsyncSelect: FC<FormAsyncSelectProps> = ({
	onSelect,
	disabled,
	placeholder,
	isMutli,
	label,
	value,
}) => {
	const [inputValue, setInputValue] = useState("");
	const handleCreate = async (inputValue: string) => {
		onSelect({ label: inputValue, value: inputValue });
	};

	const mapOptions = (options: ITag[]) =>
		options.map((option) => ({
			label: option.label,
			value: option.value,
			data: option,
		}));

	const fetchOptions = async (inputValue: string) => {
		try {
			if (inputValue) {
				const response = await getTags(inputValue);
				return mapOptions(response.items);
			}
			return [];
		} catch (e) {
			console.warn(e);
			return [];
		}
	};

	const loadOptionsDebounced = useCallback(
		debounce((inputValue: string, callback: (options: any) => void) => {
			fetchOptions(inputValue).then((options) => callback(options));
		}, 500),
		[],
	);

	const onChange = async (option: ITagOption) => {
		try {
			onSelect(option);
			setInputValue("");
		} catch (e) {
			console.log(e);
		}
	};
	const onBlur = (props: any) => console.log(props);

	return (
		<Box minW={"200px"}>
			{!!label && (
				<FormLabel>
					<Heading size={"sm"}>{label}</Heading>
				</FormLabel>
			)}
			<AsyncCreatableSelect
				placeholder={placeholder || "Search for tags..."}
				onChange={(option) => onChange(option as unknown as ITagOption)}
				onBlur={onBlur}
				isDisabled={disabled}
				onInputChange={(newValue) => setInputValue(newValue)}
				value={value}
				inputValue={inputValue}
				onCreateOption={(val) => handleCreate(val)}
				loadOptions={loadOptionsDebounced}
				formatCreateLabel={(inputValue: string) => `Use "${inputValue}"`}
				noOptionsMessage={() => "Start typing"}
				isMulti={isMutli}
				components={{
					DropdownIndicator: () => null,
					IndicatorSeparator: () => null,
				}}
			/>
		</Box>
	);
};

export default TagAsyncSelect;
