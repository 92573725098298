import { ITelephone, ITelephoneInput } from "@interfaces/types";
import { values } from "mobx";

export const fullName = (data: any) =>
	`${data?.firstName || data?.name || ""} ${
		data?.lastName || data?.surname || ""
	}`;

export const localizeDate = (dateISO?: string | null, locale?: string) => {
	if (!dateISO) return;
	const date = dateISO ? new Date(dateISO) : new Date();
	if (!date || isNaN(date?.getTime())) {
		console.warn("Date is invalid", dateISO);
		return null;
	}
	return date.toLocaleDateString(locale);
};

export const toTelephoneInput = (telephone?: ITelephone) => ({
	code: { value: telephone?.code as string, label: telephone?.code as string },
	number: telephone?.number,
});
export const toTelephone = (telephoneInput: ITelephoneInput) => ({
	code: telephoneInput?.code?.label || null,
	number: telephoneInput?.number || null,
});
export const toTelephoneDisplay = (telephone?: ITelephone) =>
	telephone?.code || telephone?.number
		? `${telephone?.code || ""} ${telephone?.number || ""}`
		: "";
