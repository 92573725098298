const colors = {
	brand: {
		900: "#102b4f",
		800: "#1c3a59",
		700: "#27496b",
		600: "#325170",
		500: "#3e5e7f",
		400: "#476b8e",
	},
	header: {
		100: "#20232a",
	},
	main: {
		100: "#282c34",
	},
};

export const mainBgColor = ["gray.100", "gray.800"];
export const brandColor = [colors.brand["700"], colors.brand["500"]];

export default colors;
