import { Wrap, WrapItem, WrapItemProps, WrapProps } from "@chakra-ui/react";
import React from "react";
import { ITag } from "@interfaces/api/tags.types";
import { ITagProps, Tag } from "@components/tags/tag";

export interface ITagGroupProps extends WrapProps {
	tags?: ITag[];
	TagComponent?: React.FC<ITagProps>;
	tagProps?: ITagProps;
	wrapItemProps?: WrapItemProps;
}
export function TagGroup({
	tags = [],
	TagComponent,
	tagProps = {},
	wrapItemProps = {},
	children,
	...rest
}: ITagGroupProps) {
	return (
		<Wrap {...rest}>
			{tags?.map((tag) => (
				<WrapItem key={tag.value} {...wrapItemProps}>
					{TagComponent ? (
						<TagComponent tag={tag} {...tagProps} />
					) : (
						<Tag tag={tag} {...tagProps} />
					)}
				</WrapItem>
			))}
			{children}
		</Wrap>
	);
}
