import React, { FC, ReactNode, ReactText, useState } from "react";
import {
	Badge,
	Box,
	BoxProps,
	Button,
	CloseButton,
	Drawer,
	DrawerContent,
	Flex,
	FlexProps,
	HStack,
	Icon,
	IconButton,
	Link,
	Stack,
	Text,
	Tooltip,
	useColorModeValue,
	useDisclosure,
	VStack,
} from "@chakra-ui/react";
import { FaFileContract, FaFileInvoiceDollar, FaUsers } from "react-icons/fa";
import { MdPersonSearch } from "react-icons/md";
import { IconType } from "react-icons";
import { useRouter } from "next/router";
import { IUser, UserTypeEnum } from "@interfaces/api/auth.types";
import SvgLogoIcon from "@components/icons/Logo";
import {
	brandColor,
	mainBgColor,
} from "@definitions/chakra/theme/foundations/colors";
import useAuthContext from "@hooks/useAuthContext";
import { useQuery } from "@tanstack/react-query";
import { currentUserQK } from "@definitions/react-query.keys";
import { getUser } from "@api/auth.api";

interface LinkItemProps {
	name: string;
	path: string;
	icon?: IconType;
	restrictedFor?: UserTypeEnum[];
}
interface SidebarProps {
	children?: ReactNode;
}
const LinkItems: Array<LinkItemProps> = [
	{
		name: "Job offers",
		path: "/job-offers",
		icon: FaFileContract,
		restrictedFor: [UserTypeEnum.Admin, UserTypeEnum.Consultant],
	},
	{
		name: "Candidates",
		path: `/users?type=${UserTypeEnum.Candidate}`,
		icon: MdPersonSearch,
		restrictedFor: [UserTypeEnum.Admin, UserTypeEnum.Consultant],
	},
	{
		name: "Clients",
		path: "/clients",
		icon: FaFileInvoiceDollar,
		restrictedFor: [UserTypeEnum.Admin, UserTypeEnum.Consultant],
	},
	{
		name: "Co-workers",
		path: `/users?type=${UserTypeEnum.Admin},${UserTypeEnum.Consultant}`,
		icon: FaUsers,
		restrictedFor: [UserTypeEnum.Admin, UserTypeEnum.Consultant],
	},
];

const SimpleSidebar: FC<SidebarProps> = ({ children, ...props }) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const sidebarColor = useColorModeValue("transparent", "transparent");
	const [hovered, setHovered] = useState(false);

	return (
		<VStack
			justify={"start"}
			align={"start"}
			minH={{ base: "100px", lg: "100vh" }}
			py={0}
			mt={"-0.5em"}
			bg={sidebarColor}
			onMouseOver={() => setHovered(true)}
			onMouseLeave={() => setHovered(false)}
			{...props}
		>
			<SidebarContent
				onClose={() => onClose}
				display={{ base: "none", md: "block" }}
				hovered={hovered}
			/>
			<Drawer
				autoFocus={false}
				isOpen={isOpen}
				placement="left"
				onClose={onClose}
				returnFocusOnClose={false}
				onOverlayClick={onClose}
				size="full"
			>
				<DrawerContent>
					<SidebarContent onClose={onClose} />
				</DrawerContent>
			</Drawer>
			{/* mobilenav */}
			<MobileNav onOpen={onOpen} />
			<Box ml={{ base: 0, md: 60 }} p="4">
				{children}
			</Box>
		</VStack>
	);
};

interface SidebarContentProps extends BoxProps {
	onClose: () => void;
	hovered?: boolean;
}

const SidebarContent = ({ onClose, hovered, ...rest }: SidebarContentProps) => {
	const { authData, authToken } = useAuthContext();
	const logoColor = useColorModeValue(brandColor[0], brandColor[1]);
	const sidebarColor = useColorModeValue("gray.200", "gray.900");

	return (
		<VStack
			w={{ base: "full", md: hovered ? "200px" : "80px" }}
			align={{ base: "center", md: "start" }}
			justify="center"
			pos="fixed"
			px={2}
			style={{ transition: "all 0.2s ease-in-out" }}
			h={"100vh"}
			bg={sidebarColor}
			{...rest}
		>
			<Flex
				alignItems="start"
				mx={hovered ? 8 : 0}
				justifyContent="start"
				transition="margin 0.2s ease-in-out"
			>
				<SvgLogoIcon
					height={60}
					width={"auto"}
					color={logoColor}
					style={{ transition: "all 0.2s ease-in-out" }}
				/>
				<CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
			</Flex>
			{LinkItems.map((link) => {
				return !link.restrictedFor ||
					(!!authData && link.restrictedFor.includes(authData.role.value)) ? (
					<NavItem
						key={link.name}
						href={link.path}
						icon={link.icon}
						label={link.name}
						userData={authData}
						full={hovered}
					/>
				) : null;
			})}
		</VStack>
	);
};

interface NavItemProps extends FlexProps {
	children?: ReactText;
	href: string;
	label: string;
	icon?: IconType;
	full?: boolean;
	userData?: IUser | null;
}
const NavItem = ({
	icon,
	href,
	children,
	label,
	full,
	userData,
	...rest
}: NavItemProps) => {
	const router = useRouter();
	const activeBg = useColorModeValue("white", "gray.700");
	const inactiveBg = useColorModeValue("gray.200", "gray.900");
	const activeColor = useColorModeValue("gray.900", "white");
	const inactiveColor = useColorModeValue("gray.500", "gray.400");
	const iconColor = useColorModeValue(brandColor[0], brandColor[1]);

	const isActive = router.asPath === href;
	return (
		<Link
			href={href}
			display={"flex"}
			style={{ textDecoration: "none", display: "flex" }}
			_focus={{ boxShadow: "none" }}
			w={{ base: 60, md: "full" }}
		>
			{/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
			{/*@ts-ignore*/}
			<Button
				boxSize="initial"
				justifyContent="center"
				position={"relative"}
				alignItems="center"
				bg={isActive ? activeBg : "transparent"}
				boxShadow={isActive ? `0px 3px 4px ${iconColor}30` : "none"}
				mx={{
					xl: "auto",
				}}
				py="8px"
				ps={{
					sm: "10px",
					xl: "16px",
				}}
				borderRadius="15px"
				w={full ? "100%" : "50px"}
				transition={"width 0.2s ease-in-out"}
				_hover={{
					bg: activeBg,
				}}
				_active={{
					bg: "inherit",
					transform: "none",
					borderColor: "transparent",
				}}
				_focus={{
					boxShadow: `0px -2px 0px ${iconColor}40`,
				}}
				{...rest}
			>
				{icon && (
					<Flex
						alignItems={"center"}
						justifyContent={"center"}
						borderRadius={"12px"}
						bg={isActive ? activeBg : inactiveBg}
						color={iconColor}
						h="30px"
						w="30px"
					>
						<Icon
							mr="0"
							fontSize="16"
							_groupHover={{
								color: "white",
							}}
							as={icon}
						/>
					</Flex>
				)}
				<Text
					color={isActive ? activeColor : inactiveColor}
					my="auto"
					fontSize="sm"
					maxW={full ? "auto" : "0px"}
					overflow={"hidden"}
					transition={"max-width 0.1s ease-in-out"}
				>
					{label}
				</Text>
				{href === "/job-offers" &&
					!!userData &&
					userData?.newJobRequestsCount > 0 && (
						<Tooltip
							label={`You have ${userData?.newJobRequestsCount} new applications`}
							placement={"top"}
						>
							<Badge
								colorScheme={"pink"}
								variant={"subtle"}
								fontSize={"1em"}
								position={"absolute"}
								top={0}
								right={0}
							>
								{userData?.newJobRequestsCount}
							</Badge>
						</Tooltip>
					)}
			</Button>
		</Link>
	);
};

interface MobileProps extends FlexProps {
	onOpen: () => void;
}
const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
	const iconColor = useColorModeValue(brandColor[0], brandColor[1]);

	const { authData, authToken } = useAuthContext();
	const logoColor = useColorModeValue(brandColor[0], brandColor[1]);
	const sidebarColor = useColorModeValue("gray.200", "gray.900");

	return (
		<HStack
			w={"100vw"}
			display={{ base: "flex", md: "none" }}
			align={{ base: "center", md: "start" }}
			spacing={1}
			justify="center"
			pos="fixed"
			zIndex={99}
			px={2}
			mt={"-0.5em"}
			style={{ transition: "all 0.2s ease-in-out" }}
			h={"80px"}
			bg={sidebarColor}
		>
			<Flex
				alignItems="start"
				mx={0}
				justifyContent="start"
				transition="margin 0.2s ease-in-out"
			>
				<SvgLogoIcon
					width={"50px"}
					height={"50px"}
					color={logoColor}
					style={{ transition: "all 0.2s ease-in-out" }}
				/>
			</Flex>
			{LinkItems.map((link) => {
				return !link.restrictedFor ||
					(!!authData && link.restrictedFor.includes(authData.role.value)) ? (
					<NavItem
						key={link.name}
						href={link.path}
						icon={link.icon}
						label={link.name}
						userData={authData}
						full={false}
					/>
				) : null;
			})}
		</HStack>
	);
};

export default SimpleSidebar;
