import { Obj } from "@interfaces/types";
import { PaginationState, SortingState } from "@tanstack/react-table";

export const convertToSearchParams = (filters?: Obj) => {
	const params = new URLSearchParams();
	if (filters) {
		Object.keys(filters)?.forEach((filter) => {
			const key = filter;
			if (typeof filters[key] === "string" && !!filters[key]) {
				filters[key]
					.split(",")
					?.forEach((item: string) => params.append(key, item));
			}
			if (typeof filters[key] === "object") {
				const param = filters[key] as string[];
				param?.forEach((val) => params.append(filter, val.toString()));
			}
		});
	}
	return params;
};

export const convertPagingParams = (data?: PaginationState) => {
	const params = new URLSearchParams();
	const { pageIndex = 0, pageSize = 0 } = data || {};
	const take = pageSize;
	const skip = pageIndex * pageSize;
	params.append("Take", take.toString());
	params.append("Skip", skip.toString());
	return params;
};

export const convertSortingParams = (data?: SortingState) => {
	const params = new URLSearchParams();
	const { id, desc } = data?.[0] || {};
	if (!id) return null;
	params.append("OrderBy.Name", id.toString());
	params.append("OrderBy.Desc", desc!.toString());
	return params;
};

const convertToUrlParams = (data?: {
	pagination?: PaginationState;
	sorting?: SortingState;
	filters?: Obj;
}) => {
	const { pagination, filters, sorting } = data || {};
	const searchParams = convertToSearchParams(filters);
	const pagingParams = convertPagingParams(pagination);
	const sortingParams = convertSortingParams(sorting);
	const params = new URLSearchParams();
	//@ts-ignore
	for (const [key, val] of searchParams.entries()) {
		params.append(key, val);
	}
	//@ts-ignore
	for (const [key, val] of pagingParams.entries()) {
		params.append(key, val);
	}
	if (sortingParams) {
		//@ts-ignore
		for (const [key, val] of sortingParams.entries()) {
			params.append(key, val);
		}
	}
	return params;
};

export default convertToUrlParams;
