import React, { FC, ReactNode } from "react";
import { Container, ContainerProps, useColorModeValue } from "@chakra-ui/react";
import { brandColor } from "@definitions/chakra/theme/foundations/colors";

interface SheetProps extends ContainerProps {
	children?: ReactNode;
}

const Sheet: FC<SheetProps> = ({ children, ...rest }) => {
	const bgColor = useColorModeValue("white", "gray.800");
	const shadowColor = useColorModeValue(brandColor[0], brandColor[1]);

	return (
		<Container
			bg={bgColor}
			boxShadow={`0px 2px 4px ${shadowColor}30`}
			borderRadius={5}
			p={{ base: 2, lg: 6 }}
			maxW={{ base: "100vw", lg: "unset" }}
			overflow={"auto"}
			{...rest}
		>
			{children}
		</Container>
	);
};

export default Sheet;
