export const LSAuthToken = "auth-token";
export const LSTemporaryToken = "temporary-token";
export const LSAuthData = "auth-data";
export const LSAccountMessageShown = "account-message-shown";

//filters
export const LSFiltersJobOffers = "filters-job-offers";
export const LSPaginationJobOffers = "pagination-job-offers";
export const LSSortingJobOffers = "sorting-job-offers";

export const LSFiltersCandidateJobOffers = "filters-candidate-job-offers";
export const LSPaginationCandidateJobOffers = "pagination-candidate-job-offers";
export const LSSortingCandidateJobOffers = "sorting-candidate-job-offers";

export const LSFiltersCandidates = "filters-candidates";
export const LSFiltersFormCandidates = "filters-form-candidates";
export const LSPaginationCandidates = "pagination-candidates";
export const LSSortingCandidates = "sorting-candidates";

export const LSFiltersClients = "filters-clients";
export const LSPaginationClients = "pagination-clients";
export const LSSortingClients = "sorting-clients";

// drafts
export const LSFormDrafts = "form-drafts";
export const LSJobOfferFormDraft = (id: string | number) =>
	`${id}-job-offer-form`;
export const LSUserFormDraft = (id: string | number) => `${id}-user-form`;
export const LSCompanyFormDraft = (id: string | number) => `${id}-company`;
export const LSCompanyContactFormDraft = (id: string | number) =>
	`${id}-company-contact`;
