import React, { FC, useMemo, useState } from "react";
import {
	Box,
	Center,
	Heading,
	useColorModeValue,
	useToast,
	VStack,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { useRouter } from "next/router";
import { UserTypeEnum } from "@interfaces/api/auth.types";
import Sheet from "@components/layout/sheet/sheet";
import ProfileNavLink from "@components/navigation/profile-nav-link/profile-nav-link";
import { SectionQueryOptions } from "@interfaces/routes.types";
import useQueryObserver from "../../../hooks/useQueryObserver";
import useAuthContext from "@hooks/useAuthContext";
import { Button, PageContainer } from "@components";
import PlatformTagsEditor from "@components/views/tags-editor/platform-tags-editor";

const JobOfferPage: FC = () => {
	const router = useRouter();
	const { authData } = useAuthContext();

	const [section, setSection] = useState("tags");

	const canEdit = useMemo(
		() =>
			authData?.role.value === UserTypeEnum.Admin ||
			authData?.role.value === UserTypeEnum.Consultant,
		[authData, authData?.role],
	);

	const navLinks = useMemo(() => {
		const baseLinks = [
			{
				label: "Tags",
				section: "tags",
			},
		];
		return baseLinks;
	}, [canEdit]);

	const bg = useColorModeValue("gray.200", "gray.700");
	return (
		<PageContainer>
			<Sheet maxW={{ base: "100%", lg: 300 }}>
				<Heading mb={4} size={"md"}>
					Platform settings
				</Heading>
				<VStack align={"center"} spacing={4}>
					{navLinks.map((link, i) => (
						<Box w="100%" key={link.section}>
							<Center
								w={"100%"}
								bg={section === link.section ? bg : "transparent"}
								borderLeft={"2px solid"}
								borderLeftColor={
									section === link.section ? "brand.600" : "transparent"
								}
							>
								<Button variant="link" onClick={() => setSection(section)}>
									{link.label}
								</Button>
							</Center>
						</Box>
					))}
				</VStack>
			</Sheet>
			<Sheet>
				{(section === "tags" || !section) && (
					<>
						<PlatformTagsEditor />
					</>
				)}
			</Sheet>
		</PageContainer>
	);
};

export default JobOfferPage;
