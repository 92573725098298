import axios, { AxiosInstance, AxiosResponse } from "axios";
import { Obj } from "@interfaces/types";
import { readFromStorage } from "@utils/local-storage";
import { LSAuthToken, LSTemporaryToken } from "@definitions/local-storage.keys";
import clearLocalStorage from "@utils/clear-local-storage";

export interface IAxiosConfig {
	baseURL?: string;
	headers?: Obj;
	timeout?: number;
	withCredentials?: boolean;
	with401Interceptor?: boolean;
}

export const axiosClient = (config?: IAxiosConfig): AxiosInstance => {
	const {
		baseURL = process.env.NEXT_PUBLIC_API_URL,
		headers: customHeaders = {},
		timeout = 60 * 1000 * 5,
		withCredentials = true,
		with401Interceptor = true,
	} = config || {};
	const headers: Obj = {
		"Content-Type": "application/json",
		Accept: "application/json",
		...customHeaders,
	};
	if (withCredentials) {
		const authToken = readFromStorage(LSAuthToken);
		const temporaryToken = readFromStorage(LSTemporaryToken);
		if (temporaryToken) {
			headers["TemporaryToken"] = `${temporaryToken}`;
		} else {
			headers["Authorization"] = `Bearer ${authToken}`;
		}
	}
	const client = axios.create({
		baseURL,
		headers,
		timeout,
		withCredentials,
	});

	if (with401Interceptor) {
		client.interceptors.response.use(
			function (response) {
				// Any status code that lie within the range of 2xx cause this function to trigger
				// Do something with response data
				return response;
			},
			function (error) {
				const authToken = readFromStorage(LSAuthToken);
				// Any status codes that falls outside the range of 2xx cause this function to trigger
				// Do something with response error
				console.log("Error", error.response);
				if (error.response.status === 401 && !!authToken) {
					console.log(
						"Token is expired. Redirecting to home page and login out...",
					);
					clearLocalStorage();
					window?.location.replace("/");
				}
				return Promise.reject(error);
			},
		);
	}

	return client;
};

export const emptyListResponse = { items: [], count: 0, total: 0 };

export const checkURL = async (url: string): Promise<unknown> => {
	try {
		const axios = axiosClient({ baseURL: "", withCredentials: false });
		const resp: AxiosResponse<unknown> = await axios.get(url);
		return resp.data;
	} catch (err: any) {
		throw err.response;
	}
};
