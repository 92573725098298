import { createContext, FC, useState } from "react";

export type ChatContextType = {
	chatId: string;
	updateId: (id: string) => void;
	setChatId: (id: string) => void;
};

export const ChatContext = createContext<ChatContextType | null>(null);

const ChatContextProvider: FC = ({ children }) => {
	const [chatId, setChatId] = useState("");

	const updateId = (id: string) => {
		setChatId(id);
	};

	return (
		<ChatContext.Provider value={{ chatId, updateId, setChatId }}>
			{children}
		</ChatContext.Provider>
	);
};

export default ChatContextProvider;
