import { Tag as ChakraTag, TagLabelProps, TagProps } from "@chakra-ui/tag";
import { IconProps, TagLabel, TagRightIcon } from "@chakra-ui/react";
import { VscChromeClose } from "react-icons/vsc";
import React from "react";
import { ITag } from "@interfaces/api/tags.types";

export interface ITagProps extends Omit<TagProps, "onClick"> {
	tag?: ITag;
	onRemove?: (tag: ITag) => void;
	tagRightIconProps?: IconProps;
	tagLabelProps?: TagLabelProps;
	onClick?: (tag: ITag) => void;
}
export function Tag({
	tag,
	onRemove,
	tagRightIconProps = {},
	tagLabelProps = {},
	onClick,
	children,
	...rest
}: ITagProps) {
	console.log(tag);
	return tag ? (
		<ChakraTag
			wordBreak={"break-all"}
			size={"md"}
			variant="solid"
			colorScheme={"blue"}
			fontWeight={600}
			cursor={onClick ? "pointer" : "default"}
			onClick={() => onClick && onClick(tag)}
			{...rest}
		>
			{!!tag.label && <TagLabel {...tagLabelProps}>{tag.label}</TagLabel>}
			{!!onRemove && (
				<TagRightIcon
					onClick={() => onRemove(tag)}
					as={VscChromeClose}
					color={"white"}
					style={{ cursor: "pointer" }}
					{...tagRightIconProps}
				/>
			)}
			{children}
		</ChakraTag>
	) : null;
}
