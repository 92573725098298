import React, { FC } from "react";
import { createTag, getTags, killTag } from "@api/tags.api";
import FDC from "@components/layout/fetch-dependable-content/FDC";
import { ITagProps, Tag } from "@components/tags/tag";
import { TagGroup } from "@components/tags/tag-group";
import { withTagGroupPopover } from "@components/tags/withTagGroupPopover";
import { useQuery } from "@tanstack/react-query";
import { tagsQK } from "@definitions/react-query.keys";
import { Box, Heading, useToast } from "@chakra-ui/react";
import TagAsyncSelect, {
	ITagOption,
} from "@components/tag-async-select/tag-async-select";
import { ITag } from "@interfaces/api/tags.types";

interface PlatformTagsEditorProps {
	colorScheme?: string;
}

const PlatformTagsEditor: FC<PlatformTagsEditorProps> = ({ colorScheme }) => {
	const toast = useToast();
	const {
		data: tags,
		isLoading,
		refetch,
	} = useQuery({
		queryKey: tagsQK(),
		queryFn: () => getTags(),
		refetchOnWindowFocus: false,
	});

	const onTagCreate = async (tag: ITagOption) => {
		try {
			await createTag(tag.label);
			await refetch();
		} catch (e) {
			toast({
				title: "Request failed",
				description: "Error occurred when creating a task",
				status: "error",
				duration: 4000,
				isClosable: true,
			});
		}
	};

	const onTagDelete = async (tag: ITag) => {
		try {
			await killTag(tag.label);
			await refetch();
		} catch (e) {
			toast({
				title: "Request failed",
				description: "Error occurred when creating a task",
				status: "error",
				duration: 4000,
				isClosable: true,
			});
		}
	};

	return (
		<>
			<Heading size={"md"} mb={4}>
				List of all tags available on the platform
			</Heading>
			<Box maxW={"300px"} mb={4}>
				<TagAsyncSelect onSelect={onTagCreate} />
			</Box>
			<FDC isLoading={isLoading}>
				<TagGroup
					tags={tags?.items || []}
					tagProps={{ onRemove: onTagDelete }}
					TagComponent={(props) =>
						withTagGroupPopover<ITagProps>(Tag, props)({ tag: props.tag })
					}
				/>
			</FDC>
		</>
	);
};

export default PlatformTagsEditor;
