import { IUploadedFile } from "@interfaces/api/file-upload.types";
import { ISelect } from "@interfaces/api/select-options.types";
import { ITag } from "@interfaces/api/tags.types";
import { IOption } from "@components/form/form-async-select/form-async-select";
import { ITelephone } from "@interfaces/types";

export interface ILoginReq {
	login: string;
	password?: string;
}

export interface IChangePasswordReq {
	password: string;
	confirmPassword: string;
	token?: string;
}

export interface ILoginResp extends IUser {
	authToken: string;
}
export enum UserStatusEnum {
	"NotVerified",
	"Verified",
}
export enum UserTypeEnum {
	"Undefined",
	"Consultant",
	"Admin",
	"Candidate",
}

export interface IUserForm {
	roleId?: UserTypeEnum;
	statusId?: UserStatusEnum;
	login?: string;
	firstName?: string;
	lastName?: string;
	tags?: string[];
	telephone?: ITelephone;
	industryId?: number;
	activityId?: number;
	deskId?: number;
	jobTitle?: string;
	jobTitleSelectId?: number;
	employerId?: number;
	password?: string;
	confirmPassword?: string;
	country?: IOption;
	workCountry?: IOption;
	city: IOption;
	workCity: IOption;
	consultantId?: number;
	languageIds?: number[];
	notes?: string;
}

export type IUserFormData = IUserForm & {
	telephoneInput: { code: IOption; number: string | null };
	roleSelect: IOption;
	statusSelect: IOption;
	positionSelect: IOption;
	industrySelect: IOption;
	deskSelect: IOption;
	jobTitleSelect: IOption;
	activitySelect: IOption;
	employerSelect: IOption;
	countrySelect: IOption;
	workCountrySelect: IOption;
	consultantSelect?: IOption;
	languagesSelect?: IOption[];
};

export interface IUpdateUserData extends IUserForm {
	id: number | null;
}

export interface IUser {
	id: number;
	chatId: string;
	firstName: string;
	lastName: string;
	status: {
		value: UserStatusEnum;
		label: keyof UserStatusEnum;
	};
	role: {
		value: UserTypeEnum;
		label: keyof UserTypeEnum;
	};
	telephone: ITelephone;
	newJobRequestsCount: number;
	avatar: string;
	login: string;
	tags: ITag[];
	created: string;
	industry: ISelect & { activity: ISelect };
	jobTitle?: string;
	jobTitleSelect?: ISelect;
	employer: ISelect;
	country: ISelect;
	workCountry: ISelect;
	city: ISelect;
	workCity: ISelect;
	desk: ISelect;
	cv: IUploadedFile;
	filesUploaded: {
		items: IUploadedFile[];
	};
	consultant?: IUser | null;
	languages?: IOption[];
	notes?: string;
	source: { value: 1 | 2; label: "SelfRegistered" | "ConsultantCreated" };
	validTo: string; //iso
	lastChanged?: string; // iso
	rgdp: {
		id: number;
		dateTimeCreated: string; // iso
		accepted: boolean;
		version: number;
		acceptedDateTime: string; // iso
	} | null;
}

export interface IUnverifiedUser extends IUser {
	temporaryToken: string;
	anyFail: boolean;
}

export interface IUserFilters {
	input?: string;
	roles?: string | string[];
	consultants?: number[];
	jobTitleSelectIds?: number[];
	industries?: number[];
	activityIds?: number[];
	employerIds?: number[];
	deskIds?: number[];
	languageIds?: number[];
	tags?: string[];
	countries?: string;
	cities?: string[];
	workCountries?: string[];
	workCities?: string[];
	workLocations?: string[];
	jobTitle?: string;
}
