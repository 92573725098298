export const writeToStorage = (key: string, data: any) => {
	try {
		if (typeof window !== "undefined") {
			const jsonData = JSON.stringify(data);
			localStorage.setItem(key, jsonData);
		}
	} catch (e) {
		console.log("Attempt to write to storage has failed", { key, data });
		console.warn("Failed to write to storage", e);
	}
};

export const readFromStorage = (key: string) => {
	try {
		if (typeof window !== "undefined") {
			const data = JSON.parse(localStorage.getItem(key) || "");
			return data || null;
		}
	} catch (e) {
		console.log("Attempt to read from storage has failed", { key });
		console.warn("Failed to read from storage", e);
		writeToStorage(key, null);
		return null;
	}
};

export const removeFromStorage = (key: string) => {
	try {
		if (typeof window !== "undefined") {
			localStorage.removeItem(key);
		}
	} catch (e) {
		console.log("Attempt to remove from storage has failed", { key });
		console.warn("Failed to write to storage", e);
	}
};
