import React, { FC, useEffect } from "react";
import { useToast } from "@chakra-ui/react";

const toast = (
	title: string | null,
	description?: string | null,
	options?: {
		status?: "success" | "error" | "warning";
		duration?: number;
		isClosable?: boolean;
	},
) => {
	return () => {
		const {
			status = "success",
			duration = 4000,
			isClosable = true,
		} = options || {};
		const toast = useToast();

		useEffect(() => {
			toast({
				title,
				description,
				status,
				duration,
				isClosable,
			});
		}, []);

		return null;
	};
};

export default toast;
