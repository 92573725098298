import React, { HTMLInputTypeAttribute, useMemo } from "react";
import {
	FormControl,
	FormErrorMessage,
	FormHelperText,
	FormLabel,
	Heading,
	Input,
} from "@chakra-ui/react";
import { FieldErrors, FormState } from "react-hook-form";
import { UseFormRegister } from "react-hook-form/dist/types/form";
import DirtyFieldHelperText from "@components/form/dirty-field-helper-text/dirty-field-helper-text";

interface FormInputProps {
	name: string;
	formRegister: UseFormRegister<any>;
	label?: string;
	disabled?: boolean;
	type?: HTMLInputTypeAttribute;
	placeholder?: string;
	formState: FormState<any>;
	shouldShowUnsavedWarning?: boolean;
	required?: boolean;
}

function FormInput({
	label,
	name,
	formRegister,
	disabled,
	type,
	placeholder,
	formState,
	shouldShowUnsavedWarning,
	required,
}: FormInputProps) {
	const { errors = {}, dirtyFields = {} } = useMemo(() => {
		return formState || {};
	}, [formState]);

	return (
		<FormControl isInvalid={!!errors[name]}>
			{!!label && (
				<FormLabel htmlFor={name}>
					<Heading size={"sm"}>
						{label}
						{required && <span style={{ marginLeft: 5, color: "red" }}>*</span>}
					</Heading>
				</FormLabel>
			)}
			<Input
				type={type}
				disabled={disabled}
				id={name}
				placeholder={placeholder}
				required={required}
				{...formRegister(name)}
			/>
			{shouldShowUnsavedWarning && dirtyFields[name] && (
				<DirtyFieldHelperText />
			)}
			<FormErrorMessage>{errors[name]?.[0]}</FormErrorMessage>
		</FormControl>
	);
}

export default FormInput;
