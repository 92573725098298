import React, { FC } from "react";
import {
	Link,
	LinkProps,
	TextProps,
	useColorModeValue,
} from "@chakra-ui/react";

const NavLink: FC<TextProps & LinkProps> = ({ children, ...props }) => {
	return (
		<Link
			px={2}
			py={1}
			rounded={"md"}
			_hover={{
				textDecoration: "none",
				bg: useColorModeValue("gray.700", "gray.700"),
			}}
			_focus={{
				color: "brand.700",
			}}
			textTransform={"uppercase"}
			href={"#"}
			{...props}
		>
			{children}
		</Link>
	);
};

export default NavLink;
