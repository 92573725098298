import { useRouter } from "next/router";
import { useEffect } from "react";
import useShallowRoute from "./useShallowRoute";
import { useToast } from "@chakra-ui/react";

const useGDPRQuery = () => {
	const router = useRouter();
	const toast = useToast();
	const shallowNavigate = useShallowRoute();

	useEffect(() => {
		if (router.query?.rgpdAccepted) {
			toast({
				title: "You have accepted GDPR consent",
				status: "success",
				duration: 4000,
				isClosable: true,
			});
			shallowNavigate(router.pathname, {});
		}
	}, [router.query]);
};

export default useGDPRQuery;
