import {
	Avatar,
	Box,
	Button,
	Center,
	Heading,
	HStack,
	Link,
	Menu,
	MenuButton,
	MenuDivider,
	MenuItem,
	MenuList,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Portal,
	Stack,
	Text,
	Tooltip,
	useColorMode,
	useColorModeValue,
	useDisclosure,
	VStack,
} from "@chakra-ui/react";
import { MoonIcon, SunIcon } from "@chakra-ui/icons";
import NavLink from "@components/navigation/nav-link/nav-link";
import React, { FC, useMemo } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { removeFromStorage } from "@utils/local-storage";
import { LSTemporaryToken } from "@definitions/local-storage.keys";
import { currentUserQK } from "@definitions/react-query.keys";
import { useRouter } from "next/router";
import toast from "@components/toast/toast";
import { fullName } from "@utils/formatters";
import { UserTypeEnum } from "@interfaces/api/auth.types";
import { mainBgColor } from "@definitions/chakra/theme/foundations/colors";
import { FaPlusCircle } from "react-icons/fa";
import LogIn from "@components/views/auth/log-in";
import useAuthContext from "@hooks/useAuthContext";
import { MainButton } from "@components/buttons/main-button/main-button";
import SvgLogoIcon from "@components/icons/Logo";
import { FiHome } from "react-icons/fi";
import clearLocalStorage from "@utils/clear-local-storage";
import TagAsyncSelect from "@components/tag-async-select/tag-async-select";
import SectionHeader from "@components/section-header/section-header";
import FDC from "@components/layout/fetch-dependable-content/FDC";
import { TagGroup } from "@components/tags/tag-group";
import { withTagGroupPopover } from "@components/tags/withTagGroupPopover";
import { ITagProps, Tag } from "@components/tags/tag";
import PlatformSettings from "@components/views/platform-settings/platform-settings";

export const NAVBAR_PADDING = 8;
export const NAVBAR_HEIGHT = 48 + NAVBAR_PADDING * 2;

const links = [
	{
		name: "Co-worker",
		href: "/users/create-user",
		restrictedTo: [UserTypeEnum.Admin],
	},
	{
		name: "Candidate",
		href: "/auth/register",
	},
	{
		name: "Client",
		href: "/clients/create-client",
	},
	{
		name: "Job offer",
		href: "/job-offers/create-job-offer",
	},
];

const Navbar: FC = () => {
	const { authToken, authData, updateAuthData, updateAuthToken } =
		useAuthContext();
	const { colorMode, toggleColorMode } = useColorMode();
	const textColor = useColorModeValue("black", "white.700");
	const navbarColor = useColorModeValue("brand.600", "brand.800");
	const buttonColor = useColorModeValue(mainBgColor[0], mainBgColor[1]);
	const queryClient = useQueryClient();
	const router = useRouter();
	const { isOpen, onClose, onOpen } = useDisclosure();

	const canCreate = useMemo(
		() =>
			authData?.role.value === UserTypeEnum.Admin ||
			authData?.role.value === UserTypeEnum.Consultant,
		[authData],
	);

	const isCandidate = useMemo(
		() => authData?.role.value === UserTypeEnum.Candidate,
		[authData],
	);

	const canGoBack = useMemo(() => router.pathname !== "/", [router.pathname]);

	const onLogin = () => {
		// router.reload();
	};

	const onLogout = async () => {
		updateAuthToken(null);
		updateAuthData(null);
		removeFromStorage(LSTemporaryToken);
		toast("You've been logged out successfully");
		await router.replace("/");
		await queryClient.invalidateQueries(currentUserQK());
		clearLocalStorage();
		// await router.reload();
		// await refetch();
	};

	const renderCreateMenu = () => (
		<MenuList alignItems={"center"}>
			{links.map(
				(link) =>
					(!link.restrictedTo ||
						link.restrictedTo?.includes(
							authData?.role.value as UserTypeEnum,
						)) && (
						<MenuItem key={link.name}>
							<Link
								key={link.name}
								href={link.href}
								color={textColor}
								display="flex"
								w="100%"
							>
								{link.name}
							</Link>
						</MenuItem>
					),
			)}
		</MenuList>
	);
	return (
		<>
			<Box
				bg={navbarColor}
				p={`${NAVBAR_PADDING}px`}
				w={"100%"}
				h={`${NAVBAR_HEIGHT}px`}
			>
				<HStack justify={"space-between"}>
					<HStack spacing={2} flex={1} align={"center"} justify={"start"}>
						{canGoBack && (
							<Button
								variant={"ghost"}
								_hover={{ background: "none" }}
								p={0}
								onClick={() => router.push("/")}
							>
								<FiHome color={"white"} size={25} />
							</Button>
						)}
					</HStack>
					<HStack spacing={2} flex={1} align={"center"} justify={"center"}>
						{(isCandidate || !authToken) && (
							<Tooltip label={"elkho-group.com"}>
								<Link href={"https://elkho-group.com"}>
									<SvgLogoIcon color={"white"} width={"50px"} height={"50px"} />
								</Link>
							</Tooltip>
						)}
					</HStack>
					<HStack spacing={2} flex={1} align={"center"} justify={"end"}>
						<Menu>
							{canCreate && (
								<MenuButton minW={0}>
									<HStack spacing={{ base: 1 }} align={"center"}>
										<NavLink color={buttonColor}>Create</NavLink>
										<FaPlusCircle size={14} color={"white"} />
									</HStack>
								</MenuButton>
							)}
							{renderCreateMenu()}
						</Menu>
						<HStack justify={"end"} spacing={{ base: 4 }}>
							<Menu>
								<MenuButton
									as={Button}
									rounded={"full"}
									variant={"transparent"}
									cursor={"pointer"}
									minW={0}
									_focus={{ boxShadow: "none" }}
								>
									<Avatar size={"sm"} borderRadius={8} src={authData?.avatar} />
								</MenuButton>
								<Portal>
									<MenuList alignItems={"center"}>
										<br />
										{authToken ? (
											<>
												<Center>
													<Avatar
														size={"2xl"}
														borderRadius={24}
														src={authData?.avatar}
													/>
												</Center>
												<br />
												<Center>
													<p>{fullName(authData)}</p>
												</Center>
											</>
										) : (
											<VStack px={4} spacing={2}>
												<LogIn onSuccess={onLogin} />
												<Text>or</Text>
												<MainButton
													variant={"solid"}
													onClick={() => router.push("/auth/register")}
												>
													Create account
												</MainButton>
											</VStack>
										)}
										<br />
										<Center>
											<Button onClick={toggleColorMode}>
												{colorMode === "light" ? <MoonIcon /> : <SunIcon />}
											</Button>
										</Center>
										<br />
										{authToken && (
											<>
												<MenuDivider />
												<MenuItem>
													<Link href={`/users/${authData?.id}`}>
														My account
													</Link>
												</MenuItem>
												{canCreate && (
													<MenuItem>
														<Link onClick={onOpen}>Platform settings</Link>
													</MenuItem>
												)}
												<MenuItem onClick={onLogout}>Logout</MenuItem>
											</>
										)}
									</MenuList>
								</Portal>
							</Menu>
						</HStack>
					</HStack>
				</HStack>
			</Box>
			<Modal isOpen={isOpen} onClose={onClose} size={"full"}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>
						<ModalCloseButton />
					</ModalHeader>
					<ModalBody>
						<PlatformSettings />
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
};

export default Navbar;
