import { axiosClient } from "@definitions/api";
import { AxiosResponse } from "axios";
import { ListResponse } from "@interfaces/types";
import { SelectValueTypes } from "@definitions/select-keys";
import { ITag, ITagGroup, TaggingObjectType } from "@interfaces/api/tags.types";
import { IUnverifiedUser, IUser } from "@interfaces/api/auth.types";

export const getTags = async (key?: string): Promise<ListResponse<ITag>> => {
	try {
		const axios = axiosClient();
		const resp: AxiosResponse<ListResponse<ITag>> = await axios.get(
			`Tags/Search/List/${key || ""}`,
		);
		return resp.data;
	} catch (err: any) {
		throw err.response;
	}
};

export const createTag = async (tag: string): Promise<ListResponse<ITag>> => {
	try {
		const axios = axiosClient();
		const resp: AxiosResponse<ListResponse<ITag>> = await axios.post(
			`Tags/${tag}`,
		);
		return resp.data;
	} catch (err: any) {
		throw err.response;
	}
};

export const getSuggestedTags = async (
	jobOfferId: number,
): Promise<ListResponse<ITag>> => {
	try {
		const axios = axiosClient();
		const resp: AxiosResponse<ListResponse<ITag>> = await axios.get(
			`Tags/Match/jobOffer/${jobOfferId}`,
		);
		return resp.data;
	} catch (err: any) {
		throw err.response;
	}
};

export const getTagGroup = async (key: string): Promise<ITagGroup> => {
	try {
		const axios = axiosClient();
		const resp: AxiosResponse<ITagGroup> = await axios.get(
			`Tags/Synonym/${key}/List`,
		);
		return resp.data;
	} catch (err: any) {
		throw err.response;
	}
};

export const addTag = async (
	tag: string | number,
	objectType: TaggingObjectType,
	objectId: number | string,
): Promise<IUser> => {
	try {
		const axios = axiosClient();
		const resp: AxiosResponse<IUnverifiedUser> = await axios.patch(
			`${objectType}/${objectId}/Tag/${tag}`,
		);
		return resp.data;
	} catch (err: any) {
		throw err.response;
	}
};

export const killTag = async (tag: string): Promise<ListResponse<ITag>> => {
	try {
		const axios = axiosClient();
		const resp: AxiosResponse<ListResponse<ITag>> = await axios.delete(
			`Tags/${tag}`,
		);
		return resp.data;
	} catch (err: any) {
		throw err.response;
	}
};

export const removeTag = async (
	tag: string | number,
	objectType: TaggingObjectType,
	objectId: number | string,
): Promise<IUser> => {
	try {
		const axios = axiosClient();
		const resp: AxiosResponse<IUnverifiedUser> = await axios.delete(
			`${objectType}/${objectId}/Tag/${tag}`,
		);
		return resp.data;
	} catch (err: any) {
		console.log(err.response);
		throw err.response;
	}
};

export const addTagToGroup = async (tag: string, relatedTag: string) => {
	try {
		const axios = axiosClient();
		const resp: AxiosResponse<void> = await axios.post(
			`Tags/Synonym/${tag}/${relatedTag}`,
		);
		// return resp.data;
	} catch (err: any) {
		console.log(err.response);
		throw err.response;
	}
};
export const removeTagFromGroup = async (tag: string) => {
	try {
		const axios = axiosClient();
		const resp: AxiosResponse<void> = await axios.delete(`Tags/Synonym/${tag}`);
		// return resp.data;
	} catch (err: any) {
		console.log(err.response);
		throw err.response;
	}
};

export const deleteTag = async (
	key: SelectValueTypes,
	id: number,
): Promise<void> => {
	try {
		const axios = axiosClient();
		await axios.delete(`Select/${key}/${id}`);
	} catch (err: any) {
		throw err.response;
	}
};
