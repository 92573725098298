import React from "react";
import { ButtonProps, useColorModeValue } from "@chakra-ui/react";
import { Button as BaseButton } from "@components";
import { brandColor } from "@definitions/chakra/theme/foundations/colors";

export type IButton = ButtonProps;

export const MainButton: React.FC<IButton> = ({ disabled, ...rest }) => {
	const buttonBg = useColorModeValue(brandColor[0], brandColor[1]);
	const color = "white";

	return (
		<div>
			<BaseButton
				bg={buttonBg}
				color={color}
				disabled={disabled}
				_hover={{ background: "gray.400" }}
				data-testid="btn"
				{...rest}
			/>
		</div>
	);
};
