import {
	IUserForm,
	ILoginReq,
	ILoginResp,
	IUser,
	IUnverifiedUser,
	IChangePasswordReq,
	IUserFilters,
} from "@interfaces/api/auth.types";
import { axiosClient } from "@definitions/api";
import { AxiosResponse } from "axios";
import { ListResponse } from "@interfaces/types";
import convertToParams from "@utils/convert-to-url-params";
import { PaginationState, SortingState } from "@tanstack/react-table";
import { writeToStorage } from "@utils/local-storage";
import {
	LSFiltersCandidates,
	LSFiltersFormCandidates,
	LSPaginationCandidates,
	LSSortingCandidates,
	LSTemporaryToken,
} from "@definitions/local-storage.keys";

export const login = async (credentials: ILoginReq): Promise<ILoginResp> => {
	try {
		const axios = axiosClient({ withCredentials: false });
		const resp: AxiosResponse<ILoginResp> = await axios.post(
			"Auth",
			credentials,
		);
		writeToStorage(LSTemporaryToken, null);
		return resp.data;
	} catch (err: any) {
		throw err.response;
	}
};

export const refreshAuthToken = async (): Promise<ILoginResp> => {
	try {
		const axios = axiosClient();
		const resp: AxiosResponse<ILoginResp> = await axios.patch("Auth/Refresh");
		writeToStorage(LSTemporaryToken, null);
		return resp.data;
	} catch (err: any) {
		throw err.response;
	}
};

export const resetPassword = async (data: ILoginReq): Promise<void> => {
	try {
		const axios = axiosClient({ withCredentials: false });
		const resp: AxiosResponse<void> = await axios.post(
			"User/Password/Reset",
			data,
		);
		return resp.data;
	} catch (err: any) {
		throw err.response;
	}
};

export const setNewPassword = async (
	data: IChangePasswordReq,
): Promise<void> => {
	try {
		const url = data.token ? "User/Password/Confirm" : "User/SetPassword";
		const axios = axiosClient();
		const resp: AxiosResponse<void> = await axios.patch(url, data);
		return resp.data;
	} catch (err: any) {
		throw err.response;
	}
};

export const createUser = async (
	form: Partial<IUserForm>,
): Promise<IUnverifiedUser> => {
	try {
		const axios = axiosClient();
		const resp: AxiosResponse<IUnverifiedUser> = await axios.post(`User`, form);
		return resp.data;
	} catch (err: any) {
		throw err.response;
	}
};

export const getUser = async (id?: number): Promise<IUser> => {
	try {
		const axios = axiosClient();
		const resp: AxiosResponse<ILoginResp> = await axios.get(`User/${id || ""}`);
		return resp.data;
	} catch (err: any) {
		throw err.response;
	}
};

export const getUsers = async (options: {
	pagination?: PaginationState;
	sorting?: SortingState;
	filters?: IUserFilters;
}): Promise<ListResponse<IUser>> => {
	try {
		const params = convertToParams(options);
		const axios = axiosClient();
		const resp: AxiosResponse<ListResponse<IUser>> = await axios.get(
			`User/List`,
			{ params },
		);
		return resp.data || [];
	} catch (err: any) {
		writeToStorage(LSFiltersCandidates, null);
		writeToStorage(LSFiltersFormCandidates, null);
		writeToStorage(LSPaginationCandidates, null);
		writeToStorage(LSSortingCandidates, null);
		throw err.response;
	}
};

export const updateUser = async (
	form: Partial<IUserForm>,
	id: number | null,
): Promise<IUser> => {
	try {
		const axios = axiosClient();
		const resp: AxiosResponse<IUser> = await axios.patch(
			`User/${id || ""}`,
			form,
		);
		return resp.data;
	} catch (err: any) {
		throw err.response;
	}
};

export const deleteUser = async (id: number | null): Promise<void> => {
	try {
		const axios = axiosClient();
		await axios.delete(`User?id=${id || ""}`);
	} catch (err: any) {
		throw err.response;
	}
};

export const getUserRoles = async (): Promise<
	ListResponse<{ label: string; value: number }>
> => {
	try {
		const axios = axiosClient();
		const resp: AxiosResponse<ListResponse<{ label: string; value: number }>> =
			await axios.get(`User/Roles`);
		return resp.data;
	} catch (err: any) {
		throw err.response;
	}
};

export const exportUsersToFile = async (options?: {
	pagination?: PaginationState;
	sorting?: SortingState;
	filters?: IUserFilters;
}): Promise<string> => {
	const { pagination, filters, sorting } = options || {};
	try {
		const params = convertToParams({ pagination, sorting, filters });
		const axios = axiosClient();
		const resp: AxiosResponse<string> = await axios.get(`Exporter/Users`, {
			params,
		});
		return resp.data;
	} catch (err: any) {
		throw err.response;
	}
};

// GDPR

export const sendGDPRConsent = async (id?: number): Promise<void> => {
	try {
		const axios = axiosClient();
		const resp: AxiosResponse<void> = await axios.post(`RGPD/${id || ""}`);
		return resp.data;
	} catch (err: any) {
		throw err.response;
	}
};
