import { IUserFilters, UserTypeEnum } from "@interfaces/api/auth.types";
import { FollowUpObjectType } from "@interfaces/api/follow-ups.types";
import { ICompanyFilters } from "@interfaces/api/companies.types";
import { IJobOfferFilters } from "@interfaces/api/job-offers.types";
import { SortingState } from "@tanstack/react-table";

export const authQK = () => ["auth"];

export const passwordQK = () => ["password"];

export const currentUserQK = () => ["current-user"];
export const userQK = (data?: [{ id?: number }]) => ["user", ...(data || [])];
export const usersQK = (data?: {
	pagination?: { pageIndex: number; pageSize: number };
	sorting?: SortingState;
	filters?: IUserFilters;
}) => ["users", data?.pagination, data?.sorting, data?.filters];

export const companyQK = (data?: [{ id: number }]) => [
	"company",
	...(data || []),
];

export const companiesQK = (data?: {
	pagination?: { pageIndex: number; pageSize: number };
	sorting?: SortingState;
	filters?: ICompanyFilters;
}) => ["companies", data?.pagination, data?.sorting, data?.filters];

export const companyContactsQK = (
	data: [{ companyId: number; id?: number }],
) => ["companyContacts", ...(data || [])];

export const jobOfferQK = (data?: [{ id?: number }]) => [
	"jobOffer",
	...(data || []),
];
export const jobOfferRobopostQK = (id?: number) => [
	"jobOffer-robopost",
	id
];
export const jobOfferSuggestedTagsQK = (data?: [{ id?: number }]) => [
	"jobOfferSuggestedTags",
	...(data || []),
];
export const jobOffersQK = (data?: {
	companyId?: number | null;
	pagination?: { pageIndex: number; pageSize: number };
	sorting?: SortingState;
	filters?: IJobOfferFilters;
}) => [
	"jobOffers",
	data?.companyId,
	data?.pagination,
	data?.sorting,
	data?.filters,
];
export const jobOfferLocationsQK = () => ["jobOfferLocations"];

export const jobRequestQK = (
	data: [{ userId?: number; jobOfferId?: number }],
) => ["jobRequest", ...(data || [])];
export const jobRequestsQK = (
	data?: [{ jobOfferId?: number; userId?: number; companyId?: number }],
) => ["jobRequests", ...(data || [])];

export const followUpsQk = (
	data: [{ objectId?: number; objectType?: FollowUpObjectType }],
) => ["followUps", ...(data || [])];

export const systemEventsQk = (
	data: [{ objectId?: number; objectType?: FollowUpObjectType }],
) => ["systemEventsUps", ...(data || [])];

export const selectQK = (data: [{ key: string }]) => [
	"select",
	...(data || []),
];

export const locationQk = () => ["location"];

export const countriesQk = () => ["countries"];
export const citiesQk = () => ["cities"];
export const tagsQK = () => ["tags"];
export const tagGroupsQK = (data: [{ tag?: string }]) => [
	"tagGroups",
	...(data || []),
];
