// #region Global Imports
import { BaseTheme } from "styled-components";
// #endregion Global Imports

const common: BaseTheme = {
	colors: {
		transparent: "transparent",
		darkGrey: "#282C34",
		blackGrey: "#20232A",
		white: "#FFFFFF",
	},
};

export { common };
