import React, { FC, ReactNode, useCallback } from "react";
import { Box, Center, Spinner, Text } from "@chakra-ui/react";
import { MainButton } from "@components/buttons/main-button/main-button";
import { ErrorBoundary } from "react-error-boundary";

interface FetchDependableContentProps {
	isLoading?: boolean;
	isError?: boolean;
	errorMessage?: string;
	children?: ReactNode;
}

const FDC: FC<FetchDependableContentProps> = ({
	isLoading,
	isError,
	errorMessage,
	children: content,
}) => {
	const ErrorComponent = useCallback(() => {
		return (
			<Box>
				<Text>
					Ooops! Looks like something went wrong! Error: {errorMessage}
				</Text>
				<MainButton onClick={() => window?.location.reload()}>
					Reload page
				</MainButton>
			</Box>
		);
	}, []);

	if (isLoading) {
		return (
			<Center>
				<Spinner color={"brand.700"} size={"xl"} />
			</Center>
		);
	}

	if (isError) {
		return <ErrorComponent />;
	}

	return <>{content}</>;
};

export default FDC;
