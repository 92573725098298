import {
	Popover,
	Box,
	Heading,
	PopoverArrow,
	PopoverBody,
	PopoverCloseButton,
	PopoverContent,
	PopoverHeader,
	PopoverTrigger,
	PopoverProps,
	PopoverArrowProps,
	PopoverHeaderProps,
	PopoverCloseButtonProps,
	PopoverBodyProps,
	useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { ITag } from "@interfaces/api/tags.types";
import TagAsyncSelect, {
	ITagOption,
} from "@components/tag-async-select/tag-async-select";
import { TagGroup } from "@components/tags/tag-group";
import { useQuery } from "@tanstack/react-query";
import { tagGroupsQK } from "@definitions/react-query.keys";
import { addTagToGroup, getTagGroup, removeTagFromGroup } from "@api/tags.api";
import FDC from "@components/layout/fetch-dependable-content/FDC";

export interface ITagPopoverProps extends PopoverProps {
	tag?: ITag;
	elementsProps?: {
		popoverArrowProps?: PopoverArrowProps;
		popoverHeaderProps?: PopoverHeaderProps;
		popoverCloseButtonProps?: PopoverCloseButtonProps;
		popoverBodyProps?: PopoverBodyProps;
	};
}
export function TagPopover({
	tag,
	elementsProps,
	children,
	...rest
}: ITagPopoverProps) {
	const {
		popoverCloseButtonProps = {},
		popoverHeaderProps = {},
		popoverBodyProps = {},
		popoverArrowProps = {},
	} = elementsProps || {};
	const [isOpen, setIsOpen] = useState(false);
	const toast = useToast();

	const {
		data: tagsInGroup,
		isLoading,
		isError,
		error,
		refetch,
	} = useQuery({
		queryKey: tagGroupsQK([{ tag: tag?.value }]),
		queryFn: () => getTagGroup(tag?.value || ""),
		enabled: !!tag && isOpen,
		refetchOnWindowFocus: false,
	});

	const addToGroup = async (relatedTag: ITagOption) => {
		try {
			await addTagToGroup(tag?.value || "", relatedTag.value.toString());
			await refetch();
		} catch (e) {
			toast({
				title: "Remove action failed",
				description: "Something went wrong",
				status: "error",
				duration: 4000,
				isClosable: true,
			});
		}
	};
	const removeFromGroup = async (tag: ITag) => {
		try {
			await removeTagFromGroup(tag.value);
			await refetch();
		} catch (e) {
			toast({
				title: "Remove action failed",
				description: "Something went wrong",
				status: "error",
				duration: 4000,
				isClosable: true,
			});
		}
	};

	return tag ? (
		<Popover
			trigger={"hover"}
			onOpen={() => setIsOpen(true)}
			onClose={() => setIsOpen(false)}
			{...rest}
		>
			<PopoverTrigger>
				<Box>{children}</Box>
			</PopoverTrigger>
			<PopoverContent>
				<PopoverArrow {...popoverArrowProps} />
				<PopoverHeader {...popoverHeaderProps}>
					<Box maxW={200}>
						<Heading size={"sm"} mb={2}>
							{tag.label} tag group
						</Heading>
						<TagAsyncSelect
							placeholder={"Add tags to group..."}
							onSelect={addToGroup}
						/>
					</Box>
				</PopoverHeader>
				<PopoverCloseButton {...popoverCloseButtonProps} />
				<PopoverBody {...popoverBodyProps}>
					<Box maxH={"150px"} h={"100px"} overflowY={"auto"} pr={4}>
						<FDC isLoading={isLoading} isError={isError}>
							<TagGroup
								tags={tagsInGroup?.tags || []}
								tagProps={{ onRemove: (tag) => removeFromGroup(tag) }}
							/>
						</FDC>
					</Box>
				</PopoverBody>
			</PopoverContent>
		</Popover>
	) : null;
}

export function withTagGroupPopover<ITriggerProps>(
	Trigger: React.FC<ITriggerProps>,
	triggerProps: ITriggerProps,
) {
	return (props: ITagPopoverProps) => (
		<TagPopover {...props}>
			<Trigger {...triggerProps} />
		</TagPopover>
	);
}
