import React from "react";
import { AppProps } from "next/app";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "@definitions/chakra/theme";
import { StyledThemeProvider } from "@definitions/styled-components";
import {
	QueryClient,
	QueryClientProvider,
	Hydrate,
} from "@tanstack/react-query";
import { appWithTranslation } from "next-i18next";
import SharedLayout from "@components/layout/shared-layout/shared-layout";
import DynamicChat from "@components/chat/chat.dynamic";
import "../src/components/chat/chat.scss";
import ChatContextProvider from "../src/context/chat.context";
import AuthContextProvider from "../src/context/auth.context";
import useScripts from "@hooks/useScripts";
import useGDPRQuery from "@hooks/useGDPRQuery";

function MyApp({ Component, pageProps }: AppProps): JSX.Element {
	const queryClient = new QueryClient();
	useScripts();
	return (
		<ChakraProvider theme={theme}>
			<AuthContextProvider>
				<ChatContextProvider>
					<StyledThemeProvider>
						<QueryClientProvider client={queryClient}>
							<Hydrate state={pageProps.dehydratedState}>
								<SharedLayout>
									<Component {...pageProps} />
									<DynamicChat />
								</SharedLayout>
							</Hydrate>
						</QueryClientProvider>
					</StyledThemeProvider>
				</ChatContextProvider>
			</AuthContextProvider>
		</ChakraProvider>
	);
}

export default appWithTranslation(MyApp);
