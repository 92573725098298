import { FC } from "react";
import { Flex, StackDirection, useToast } from "@chakra-ui/react";
import LogInForm, { LogInFormProps } from "@components/forms/log-in-form/log-in-form";
import {
	ILoginReq,
	ILoginResp,
	UserTypeEnum,
} from "@interfaces/api/auth.types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
	authQK,
	currentUserQK,
	passwordQK,
	userQK,
} from "@definitions/react-query.keys";
import { login, resetPassword } from "@api/auth.api";
import { IApiError } from "@interfaces/api/api.types";
import { useRouter } from "next/router";
import useAuthContext from "@hooks/useAuthContext";

interface LogInProps {
	onSuccess?: (data: ILoginResp) => void;
	onError?: () => void;
	direction?: StackDirection;
	initValues?: LogInFormProps["initValues"]
}

const LogIn: FC<LogInProps> = ({ onSuccess, direction = "column", initValues }) => {
	const { updateAuthToken, updateAuthData } = useAuthContext();
	const toast = useToast();
	const router = useRouter();
	const queryClient = useQueryClient();

	const onLoginSuccess = async (data: ILoginResp) => {
		updateAuthData(data);
		updateAuthToken(data.authToken);
		await queryClient.invalidateQueries(currentUserQK());
		await queryClient.invalidateQueries(userQK([{ id: data.id }]));
		const isWorker =
			data?.role?.value === UserTypeEnum.Consultant ||
			data?.role?.value === UserTypeEnum.Admin;
		await router.replace(isWorker ? "job-offers" : "/");

		!!onSuccess && onSuccess(data);
	};
	const onError = (error: IApiError) => {
		toast({
			title: error?.data?.codeString || "Request failed",
			description: error?.data?.error || "Something went wrong",
			status: "error",
			duration: 4000,
			isClosable: true,
		});
	};

	const onResetPasswordSuccess = async () => {
		await router.reload();
		toast({
			title: "Request for resetting password has been sent!",
			description: "Please follow the instructions in email",
			status: "success",
			duration: 4000,
			isClosable: true,
		});
	};

	const loginQuery = useMutation(login, {
		mutationKey: authQK(),
		onSuccess: onLoginSuccess,
		onError: onError,
	});

	const resetPasswordQuery = useMutation(resetPassword, {
		mutationKey: passwordQK(),
		onSuccess: onResetPasswordSuccess,
		onError: onError,
	});

	const onSubmit = async (values: ILoginReq) => {
		await loginQuery.mutateAsync(values);
	};

	const onResetPasswordSubmit = async (values: ILoginReq) => {
		await resetPasswordQuery.mutateAsync(values);
	};

	return (
		<Flex direction="column" w={"100%"} align={"center"} justify={"center"}>
			<LogInForm
				onSubmit={onSubmit}
				onResetPassword={onResetPasswordSubmit}
				direction={direction}
				initValues={initValues}
			/>
		</Flex>
	);
};

export default LogIn;
