import React from "react";
import { FormHelperText, HelpTextProps } from "@chakra-ui/react";

interface DirtyFieldHelperTextProps extends HelpTextProps {
	text?: string;
}

const DirtyFieldHelperText: React.FC<DirtyFieldHelperTextProps> = ({
	text,
	...rest
}) => {
	return (
		<FormHelperText fontStyle={"italic"} color={"yellow.500"} {...rest}>
			{text || "Field has unsaved changes"}
		</FormHelperText>
	);
};
export default DirtyFieldHelperText;
